/* hide up/down arrows ("spinners") on input fields marked type="number" */
/* from https://www.tilcode.com/css-hide-spinners-updown-arrows-on-inputs-with-typenumber-using-a-class/ */
.no-spinners {
  -moz-appearance: textfield;
}

.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
