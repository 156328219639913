.prototype-radios div {
  margin-top: 0.5rem;
}

/*
Example I based this stuff on is found at:
https://stackoverflow.com/questions/17541614/use-images-instead-of-radio-buttons
and http://jsfiddle.net/La8wQ/313/
*/
/* .prototype-radios input {
  display: none;
} */

.prototype-radios label {
  width: 160px;
  height: 100px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0);
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  transition: all 100ms ease-in;
  filter: grayscale(0.8) opacity(0.7);
}

.prototype-radios label:hover {
  filter: brightness(1.2) grayscale(0.5) opacity(0.9);
}

.prototype-radios input:active + .form-check-label {
  opacity: 0.9;
}
.prototype-radios input:checked + .form-check-label {
  filter: none;
}

.townhouse label {
  background-image: url(./../images/townhouse-sm-rec.png);
}
.townhouseLarge label {
  background-image: url(./../images/townhouse-lg-rec.png);
}
.duplex label {
  background-image: url(./../images/duplex-rec.png);
}
.sixplex label {
  background-image: url(./../images/sixplex-rec.png);
}
.fourplex label {
  background-image: url(./../images/fourplex-rec.png);
}
.multiplex label {
  background-image: url(./../images/multiplex-rec.png);
}
